.page, #page ul {
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 1600px) {
  .page, #page ul {
    margin-top: 44px;
  }
}

@media (max-width: 1366px) {
  .page, #page ul {
    margin-top: 38px;
  }
}

@media (max-width: 1024px) {
  .page, #page ul {
    margin-top: 32px;
  }
}

@media (max-width: 991px) {
  .page, #page ul {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .page, #page ul {
    margin-top: 20px;
  }
}

.page a, .page li, #page ul a, #page ul li {
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  transition: all .3s;
  color: #999;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

@media (max-width: 1600px) {
  .page a, .page li, #page ul a, #page ul li {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .page a, .page li, #page ul a, #page ul li {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .page a, .page li, #page ul a, #page ul li {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .page a, .page li, #page ul a, #page ul li {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .page a, .page li, #page ul a, #page ul li {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .page a, .page li, #page ul a, #page ul li {
    width: 48px;
  }
}

@media (max-width: 1366px) {
  .page a, .page li, #page ul a, #page ul li {
    width: 46px;
  }
}

@media (max-width: 1024px) {
  .page a, .page li, #page ul a, #page ul li {
    width: 44px;
  }
}

@media (max-width: 991px) {
  .page a, .page li, #page ul a, #page ul li {
    width: 42px;
  }
}

@media (max-width: 767px) {
  .page a, .page li, #page ul a, #page ul li {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .page a, .page li, #page ul a, #page ul li {
    height: 48px;
  }
}

@media (max-width: 1366px) {
  .page a, .page li, #page ul a, #page ul li {
    height: 46px;
  }
}

@media (max-width: 1024px) {
  .page a, .page li, #page ul a, #page ul li {
    height: 44px;
  }
}

@media (max-width: 991px) {
  .page a, .page li, #page ul a, #page ul li {
    height: 42px;
  }
}

@media (max-width: 767px) {
  .page a, .page li, #page ul a, #page ul li {
    height: 40px;
  }
}

@media (max-width: 1600px) {
  .page a, .page li, #page ul a, #page ul li {
    line-height: 48px;
  }
}

@media (max-width: 1366px) {
  .page a, .page li, #page ul a, #page ul li {
    line-height: 46px;
  }
}

@media (max-width: 1024px) {
  .page a, .page li, #page ul a, #page ul li {
    line-height: 44px;
  }
}

@media (max-width: 991px) {
  .page a, .page li, #page ul a, #page ul li {
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  .page a, .page li, #page ul a, #page ul li {
    line-height: 40px;
  }
}

@media (max-width: 1600px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-left: 4.4px;
  }
}

@media (max-width: 1366px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-left: 3.8px;
  }
}

@media (max-width: 1024px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-left: 3.2px;
  }
}

@media (max-width: 991px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-left: 2.6px;
  }
}

@media (max-width: 767px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-left: 2px;
  }
}

@media (max-width: 1600px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-right: 4.4px;
  }
}

@media (max-width: 1366px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-right: 3.8px;
  }
}

@media (max-width: 1024px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-right: 3.2px;
  }
}

@media (max-width: 991px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-right: 2.6px;
  }
}

@media (max-width: 767px) {
  .page a, .page li, #page ul a, #page ul li {
    margin-right: 2px;
  }
}

.page a:hover, .page a.active, .page a.xl-active, .page li:hover, .page li.active, .page li.xl-active, #page ul a:hover, #page ul a.active, #page ul a.xl-active, #page ul li:hover, #page ul li.active, #page ul li.xl-active {
  background-color: #3062f5;
  color: #fff;
}

.page a .iconfont, .page li .iconfont, #page ul a .iconfont, #page ul li .iconfont {
  display: inline-block;
  transform: translateY(-5%);
}

@media (max-width: 991px) {
  .page a .iconfont, .page li .iconfont, #page ul a .iconfont, #page ul li .iconfont {
    transform: translateY(-3%);
  }
}

@media (max-width: 767px) {
  .page a .iconfont, .page li .iconfont, #page ul a .iconfont, #page ul li .iconfont {
    transform: translateY(-1%);
  }
}

.page a.xl-prevPage, .page a.xl-nextPage, .page li.xl-prevPage, .page li.xl-nextPage, #page ul a.xl-prevPage, #page ul a.xl-nextPage, #page ul li.xl-prevPage, #page ul li.xl-nextPage {
  display: none;
}
