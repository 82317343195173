@import "../../assets/styles/utils";

footer{
  // background: url(./images/footer_bg.jpg) no-repeat center;
  background-size: cover;
  background-color: #263039;
  color: rgba(255,255,255,.8);
  @include res(overflow,hidden,(sm:visible));
  .footer{
    @include res(padding-top,100px,(mmd:80px,md:60px,sm:40px,xs:30px));
    @include res(padding-bottom, 100px, 20 / 100);
    @extend %clearfix;
    .left-info{
      box-sizing: border-box;
      @include res(padding-right,20px, 0 / 20);
      @include res(float,left);
      @include res(width,32.5%,(mmd:36%,md:45%,sm:100%));
      .call{
        @include res(text-align,left,(xs:center));
        @include res(float,none,(sm:left,xs:none));
        @include res(margin-right,0,(sm:40px,xs:0));
        .p1{
          color: #fff;
          @include res(font-size, 24px, 16 / 24);
        }
        .p2{
          color: rgba(255,255,255,.5);
          @include res(font-size, 18px, 14 / 18);
        }
        .p3{
          color: #fff;
          font-family:"harmony",'roboto',"PingFang SC","Microsoft YaHei";
          @include res(font-size, 36px, 22 / 36);
        }
      }
      .info{
        color: #fff;
        line-height: 1.6;
        white-space: nowrap;
        @include res(text-align,left,(xs:center));
        @include res(font-size, 18px, 14 / 18);
        @include res(margin-top,35px, 10 / 35);
        @media screen and(max-width:992px) {
          margin-top: 0;
        }
        @media screen and(max-width:1366px){
          @include res(font-size, 16px, 14 / 16);
        }
      }
      .media-icon{
        display: flex;
        align-items: center;
        @include res(justify-content,flex-start,(sm:center));
        @include res(text-align,left,(sm:center));
        @include res(margin-top,45px, 15 / 45);
        >div{
          position: relative;
          flex-shrink: 0;
          @include res(display,inline-block);
          &:not(:last-child){
            @include res(margin-right,20px, 10 / 20);
          }
          &:hover{
            .iconfont{
              color: #fff;
            }
            .ewm{
              opacity: 1;
              visibility: visible;
              transform: translateX(-50%) translateY(0px);
            }
          }
          .iconfont{
            line-height: 1;
            transition: all .5s;
            color: rgba(255,255,255,.3);
            cursor: pointer;
            @include res(font-size,40px, 30 / 40);
          }
          .ewm{
            position: absolute;
            top: 130%;
            left: 50%;
            transition: all .5s;
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            transform: translateX(-50%) translateY(5px);
            &::after{
              content: '';
              display: block;
              border: 8px solid;
              border-color: transparent transparent #fff transparent;
              position: absolute;
              left: calc(50% - 8px);
              bottom: 100%;
            }
            img{
              display: block;
              height: auto;
              @include res(width,145px, 100 / 145);
            }
          }
        }
      }
    }
    .right-nav{
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: flex-start;
      @include res(display,flex,(sm:none));
      @include res(padding-left,10%,(md:5%));
      @include res(float,right);
      @include res(width,67.5%,(mmd:64%,md:55%));
      @extend %clearfix;
      ul{
        flex-shrink: 0;
        @include res(float,left);
        @include res(width,25%);
        .nav-title{
          color: #fff;
          @include res(font-size,25px, 16 / 25);
          @include res(margin-bottom,0,(mmd:10px));
          &::after{
            content: '';
            display: block;
            background-image: linear-gradient(#0096ff,#0068ff);
            @include res(width,24px);
            @include res(height,3px);
            @include res(margin-top, 15px, 10 / 15);
            @media screen and(max-width:1366px) {
              @include res(margin-top, 10px);
            }
          }
        }
        li{
          white-space: nowrap;
          @include res(font-size, 18px, 14 / 18);
          @include res(margin-top, 15px, 10 / 15);
          @media screen and(max-width:1366px) {
            @include res(margin-top, 5px, 5 / 5);
            @include res(font-size, 16px, 14 / 16);
          }
          a{
            transition: all .3s;
            display: inline-block;
            &:hover{
              color: #fff;
              transform: translateX(5px);
            }
          }
        }
      }
    }
  }
  .footer-bottom{
    bottom: 0;
    width: 100%;
    left: 0;
    color: rgba(255,255,255,.6);
    @include res(font-size,14px, 12 / 14);
    // @include res(position,absolute,(md:static));
    a{
      transition: all .3s;
      &:hover{
        color: #fff;
      }
    }
    .friend-link{
      position: relative;
      // border-top: 1px solid rgba(255,255,255,.1);
      @include res(padding-top,50px, 10 / 50);
      @include res(padding-bottom,25px, 10 / 25);
      @media screen and(max-width:1366px) {
        @include res(padding-top,30px, 10 / 30);
      }
      // &::after{
      //   content: '';
      //   display: block;
      //   background-color: rgba(255,255,255,.1);
      //   position: absolute;
      //   width: 100%;
      //   height: 1px;
      //   top: -1px;
      //   right: 100%;
      // }
      // &::before{
      //   content: '';
      //   background-color: rgba(255,255,255,.1);
      //   position: absolute;
      //   height: 100vh;
      //   width: 1px;
      //   bottom: 100%;
      //   @include res(display,block,(sm:none));
      //   @include res(left,32.5%,(mmd:36%,md:45%));
      // }
      .cont{
        position: relative;
        @include res(padding-left,70px,(xs:0));
        &:hover{
          .link-title{
            .iconfont{
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          .link{
            @include res(visibility,null,(xs:visible));
            @include res(opacity,null,(xs:1));
            @include res(transform,translateY(0),(xs:translateY(0px)));
          }
        }
        .link-title{
          top: 0;
          left: 0;
          @include res(position,absolute,(xs:static));
          .iconfont{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            transition: all .4s;
            transform-origin: center;
            @include res(display,none,(xs:block));
            @include res(font-size, 14px);
          }
          span{
            @include res(display,null,(xs:none));
          }
        }
        .link{
          bottom: 150%;
          left: 0;
          transition: all .4s;
          z-index: 2;
          @include res(visibility,null,(xs:hidden));
          @include res(opacity,null,(xs:0));
          @include res(transform,translateY(0),(xs:translateY(-10px)));
          @include res(border-radius,0,(xs:10px));
          @include res(background-color,null,(xs:#fff));
          @include res(overflow-y,null,(xs:auto));
          @include res(max-height,null,(xs:300px));
          @include res(width,null,(xs:100%));
          @include res(position,static,(xs:absolute));
          @include res(box-shadow,null,(xs:0 0 10px rgba(0,0,0,.1)));
          a{
            position: relative;
            @include res(color,rgba(255,255,255,.6),(xs:$color-text));
            @include res(display,inline-block,(xs:block));
            @include res(padding-left,null,(xs:30px));
            @include res(padding-right,null,(xs:30px));
            @include res(padding-top,null,(xs:10px));
            @include res(padding-right,null,(xs:10px));
            &:hover{
              @include res(color,rgba(255,255,255,1),(xs:$color-main));
            }
            &:not(:last-child){
              @include res(padding-right,13px,(xs:auto));
              @include res(margin-right,7px,(xs:auto));
              &::after{
                content: '|';
                position: absolute;
                top: 0;
                right: 0;
                transform: scaleY(.8);
                transform-origin: center;
                @include res(display,null,(xs:none));
              }
            }
          }
        }
      }
    }
    .copyright{
      // border-top: 1px solid rgba(255,255,255,.1);
      @include res(text-align,null,(sm:center));
      @extend %clearfix;
      @include res(padding-bottom, 35px, 10 / 35);
      @include res(padding-top, 15px, 10 / 15);
      @media screen and(max-width:1366px) {
        @include res(padding-bottom, 25px, 10 / 25);
      }
      .left{
        @include res(float,left,(sm:none));
        a{
          &:not(:last-child){
            @include res(margin-right,35px, 10 / 35);
          }
          &.site-map{
            @include res(display,null,(md:none));
          }
        }
      }
      .right{
        white-space: nowrap;
        @include res(float,right,(sm:none));
        a{
          @include res(display,null,(xs:block));
        }
      }
    }
  }
}