@import "../../assets/styles/utils";

.page,#page ul{
  text-align: center;
  @include res(margin-top,50px, 20 / 50);
  a,li{
    display: inline-block;
    border-radius: 50%;
    background-color: #fff;
    transition: all .3s;
    color: #999;
    margin-bottom: 5px;
    cursor: pointer;
    @include res(font-size,20px, 16 / 20);
    @include res(width,50px, 40 / 50);
    @include res(height,50px, 40 / 50);
    @include res(line-height,50px, 40 / 50);
    @include res(margin-left,5px, 2 / 5);
    @include res(margin-right,5px, 2 / 5);
    &:hover,&.active,&.xl-active{
      background-color: $color-blue;
      color: #fff;
    }
    .iconfont{
      display: inline-block;
      @include res(transform,translateY(-5%),(sm:translateY(-3%),xs:translateY(-1%)));
    }
    &.xl-prevPage,&.xl-nextPage{
      display: none;
    }
  }
}