@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/dist/css/swiper.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat-regular.eot');
  src: url('../fonts/montserrat-regular.woff') format('woff'),
    url('../fonts/montserrat-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
// 此处使用正式域名的路径
// @font-face {
//   font-family: 'harmony';
//   src: url('../fonts/HarmonyOS_Sans_SC_Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'harmonybold';
//   src: url('../fonts/HarmonyOS_Sans_SC_Bold.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
@font-face {
  font-family: 'harmony';
  src: url(https://www.qinnav.com/public/files/fonts/HarmonyOS_Sans_SC_Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'harmonybold';
  src: url(https://www.qinnav.com/public/files/fonts/HarmonyOS_Sans_SC_Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}

body {
  font-family:"harmony","PingFang SC","Microsoft YaHei";
  line-height: 1.8;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, 16px);
}
.comp-root{
  overflow-x: hidden;
}
.hide{
  opacity: 0;
  visibility: hidden;
}

.pd{
  @include res(padding-top,120px, 40 / 120);
  @include res(padding-bottom,120px, 40 / 120);
}
.pdt{
  @include res(padding-top,145px, 50 / 145);
}
.pdb{
  @include res(padding-bottom,100px, 30 / 100);
}
.bg-gary{
  background-color: #f5f5f5;
}
@keyframes rain {
  0% {
    transform: translateY(5px);
    opacity: 1;
  }
  80% {
    transform: translateY(15px);
    opacity: 0;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
// 列表中图片有占位图片的
.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .5s;
  }
}


//主体内容部分宽度
.mycontainer{
  max-width: 1600px;
  margin: 0 auto;
  box-sizing: content-box;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);
}

.common-btn{
  display: inline-block;
  background-color: #fff;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  // font-family: 'roboto';
  font-family:"harmony","PingFang SC","Microsoft YaHei";
  transition: all .4s;
  @include res(font-size,20px, 16 / 20);
  @include res(padding-left,65px, 30 / 65);
  @include res(padding-right,65px, 30 / 65);
  @include res(padding-top,10px, 3 / 10);
  @include res(padding-bottom,10px, 3 / 10);
  &.blue{
    background-color: #3062f5;
    span,.iconfont{
      color: #fff;
    }
  }
  &:hover{
    background-color: #007aff;
    // &::after{
    //   transform: translateY(0%);
    // }
    span,.iconfont{
      color: #fff;
    }
  }
  // &::after{
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   background-image: linear-gradient(to bottom, #0091ff, #0056ff);
  //   position: absolute;
  //   z-index: 0;
  //   transition: all .4s $anime-bezier;
  //   transform-origin: center;
  //   transform: translateY(101%);
  // }
  span,.iconfont{
    position: relative;
    z-index: 2;
    color: $color-main-light;
    transition: all .4s;
  }
  .iconfont{
    font-weight: bold;
    font-size: 14px;
    margin-left: 3px;
    display: inline-block;
    @include res(transform,translateY(-2px),(sm:translateY(0px)));
  }
}

.ny-banner{
  position: relative;
  .bg{ 
    position: relative;
    overflow: hidden;
    @include res(height,757px,(lg:650px,mmd:500px,md:400px,sm:300px,xs:250px));
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.05);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 30%;
      background-image: linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,0));
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    b{
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .cont{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    transform: translateY(50%);
    z-index: 2;
    @include res(min-height,100px, 50 / 100);
    @media screen and(max-width:1366px) {
      @include res(min-height,80px, 50 / 80);
    }
  }
  .ny-nav{
    position: relative;
    @include res(padding-left,280px,130 / 280);
    @include res(min-height,100px, 50 / 100);
    @media screen and(max-width:1366px) {
      @include res(min-height,80px, 50 / 80);
    }
    .nav-title{
      background-color: #3062f5;
      position: absolute;
      left: 0;
      color: #fff;
      white-space: nowrap;
      line-height: 1;
      box-sizing: border-box;
      @include res(bottom,0);
      @include res(width,280px,130 / 280);
      @include res(padding-left,40px, 15 / 40);
      @include res(padding-top,60px, 20 / 60);
      @include res(padding-bottom,60px, 20 / 60);
      h1{
        @include res(letter-spacing,3px,(sm:0));
        @include res(font-size, 46px, 24 / 46);
        @include res(margin-bottom,20px, 10 / 20);
        @media screen and(max-width:1366px) {
          @include res(font-size, 38px, 24 / 38);
        }
      }
      h2{
        opacity: .5;
        @include res(letter-spacing,2px,(sm:0));
        @include res(font-size, 18px, 12 / 18);
      }
    }
    .nav{
      position: relative;
      .mob-active-nav{
        background-color: #fff;
        @include res(padding-left,50px, 20 / 50);
        @include res(padding-right,50px, 20 / 50);
        @include res(line-height,100px, 50 / 100);
        @include res(height,100px, 50 / 100);
        @include res(display,none,(sm:block));
        @media screen and(max-width:1366px) {
          @include res(line-height,80px, 50 / 80);
          @include res(height,80px, 50 / 80);
        }
        .iconfont{
          float: right;
          display: inline-block;
          transition: all .3s;
        }
        &.active{
          .iconfont{
            transform: rotate(90deg);
          }
        }
      }
      .nav-list{
        background-color: #fff;
        white-space: nowrap;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        display: flex;
        flex-wrap: wrap;
        top: 100%;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        z-index: 2;
        @include res(display,flex,(sm:none));
        @extend %clearfix;
        @include res(line-height,100px, 50 / 100);
        @include res(position,null,(sm:absolute));
        @media screen and(max-width:1366px) {
          @include res(line-height,80px, 40 / 80);
        }
        a{
          text-align: center;
          position: relative;
          transition: all .3s;
          flex-shrink: 0;
          box-sizing: border-box;
          flex-grow: 1;
          @include res(display,inline-block,(sm:block));
          @include res(font-size,22px, 14 / 22);
          @media screen and(max-width:1366px) {
            @include res(font-size, 20px, 14 / 20);
          }
          &::after{
            content: '';
            display: block;
            width: 100%;
            background-color: #3062f5;
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0;
            @include res(height,4px, 2 / 4);
            
          }
          &:not(:last-child){
            @include res(border-right,1px solid #e9edf5,(sm:none));
            @include res(border-bottom,none,(sm:1px solid #e9edf5));
          }
          &:hover{
            color: #3062f5;
          }
          &.active{
            font-weight: bold;
            color: #3062f5;
            &::after{
              opacity: 1;
            }
          }
        }
      }
    }
    .nav-gation{
      display: flex;
      align-items: center;
      position: absolute;
      right:10px;
      @include res(right, 10px, 0 / 10);
      @include res(top, -36px, -28 / -36);
      // color: #666;
      color: #fff;
      @include res(font-size, 16px, 12 / 16);
      span{
        margin: 0px 4px 0;
        &::after{
          content: '\e8d4';
          font-family: 'iconfont';
          @include res(font-size, 14px, 11 / 14);
        }
      }
    }
  }
}

.ny-title{
  line-height: 1;
  color: #000;
  @include res(margin-bottom,50px, 20 / 50);
  &.white{
    color: #fff;
  }
  h1{
    letter-spacing: 2px;
    font-weight: bold;
    @include res(margin-bottom,20px, 10 / 20);
    @include res(font-size, 44px, 26 / 44);
  }
  h2{
    opacity: .5;
    text-transform: uppercase;
    font-family:"harmony","PingFang SC","Microsoft YaHei";
    @include res(font-size, 18px, 14 / 18);
  }
}


// 产品列表
.pro-list{
  @extend %clearfix;
  @include res(width,calc(100% + 50px),(mmd:calc(100% + 30px),xs:100%));
  li{
    transition: all .5s;
    background-color: #eaeffe;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    @include res(width,calc(33.33% - 50px),(mmd:calc(33.33% - 30px),md:calc(50% - 30px),xs:100%));
    @include res(margin-right,50px,(mmd:30px,xs:0));
    @include res(margin-bottom,60px, 20 / 60);
    @include res(float,left);
    position: relative;
    @media screen and(max-width:1366px) {
      @include res(margin-bottom,40px, 20 / 40);
    }
    &:hover{
      box-shadow: 10px 10px 15px rgba(0,0,0,.1);
      .item-img{
        img{
          transform: translateX(-50%) translateY(-50%) scale(1.05);
        }
      }
      .item-t{
        .item-link{
          color: #fff;
          background-color: #3062f5;
        }
      }
    }
    .newproduct{
      @include res(width,110px, 67 / 110);
      @include res(height,110px, 67 / 110);
      position: absolute;
      top: 0;
      right: 0;
    }
    .item-img{
      background-color: #fff;
      position: relative;
      @include res(height,330px,(lg:300px,mmd:260px,xs:200px));
      img{
        display: block;
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 80%;
        // box-shadow: 10px 10px 10px rgba(0,0,0,.1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: all .5s;
      }
    }
    .item-t{
      @include res(padding-left,40px, 20 / 40);
      @include res(padding-right,40px, 20 / 40);
      @include res(padding-top,30px, 20 / 30);
      @include res(padding-bottom,30px, 20 / 30);
      .item-title{
        text-align: center;
        @include res(margin-bottom,15px);
        @include res(font-size, 18px, 14 / 18);
        h3{
          white-space: nowrap;
          @include res(margin-bottom,10px, 5 / 10);
        }
        h4{
          color: $color-desc;
          line-height: 1.2;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          @include res(height, 20px, 16 / 20);
        }
      }
      .item-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #c5d3fc;
        overflow: auto;
        overflow-y: hidden;
        white-space: nowrap;
        @include res(padding-top,30px, 15 / 30);
        .icon{
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          background-color: #d0daf8;
          border-radius: 50%;
          transition: all .5s;
          @include res(display,flex);
          @include res(width, 30px);
          @include res(height, 30px);
          &:not(:last-child){
            @include res(margin-right, 10px, 5 / 10);
          }
          &:hover{
            background-color: $color-blue;
            img{
              filter: brightness(0) invert(1);
            }
          }
          img{
            display: block;
            width: 100%;
            height: auto;
            transition: all .3s;
          }
        }
      }
      .item-link{
        text-align: center;
        color: #3062f5;
        border: 1px solid #3062f5;
        border-radius: 50px;
        transition: all .5s;
        @include res(padding-top,10px, 5 / 10);
        @include res(padding-bottom,10px, 5 / 10);
        @include res(font-size,20px, 16 / 20);
        @include res(margin-top,30px, 15 / 30);
      }
    }
  }
  &.list-four{
    @include res(width,calc(100% + 30px),(mmd:calc(100% + 30px),xs:100%));
    li{
      @include res(width,calc(25% - 30px),(mmd:calc(25% - 30px),md:calc(25% - 30px),sm:calc(50% - 30px),xs:100%));
      @include res(margin-right,30px,(mmd:30px,xs:0));
      .item-img{
        @include res(height,270px,(lg:250px,mmd:230px,xs:200px));
      }
    }
  }
}

//应用列表
.application-list{
  @extend %clearfix;
  @include res(width,calc(100% + 50px),(mmd:calc(100% + 30px),xs:100%));
  li{
    transition: all .5s;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    box-shadow: 5px 5px 10px rgba(0,0,0,.1);
    @include res(width,calc(33.33% - 50px),(mmd:calc(33.33% - 30px),md:calc(50% - 30px),xs:100%));
    @include res(margin-right,50px,(mmd:30px,xs:0));
    @include res(margin-bottom,60px, 20 / 60);
    @include res(float,left);
    @media screen and(max-width:1366px) {
      @include res(margin-bottom,40px, 20 / 40);
    }
    &::after,&::before{
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      border: solid rgba(255,255,255,.1);
      position: absolute;
      z-index: 2;
      box-sizing: border-box;
      transition: all .5s;
      @include res(opacity,0,(md:1));
      @include res(border-width,8px, 5 / 8);
    }
    &::before{
      bottom: 15%;
      right: 10%;
      @include res(transform,translateX(-10%) translateY(-10%),(md:translateX(0%) translateY(0%)));
    }
    &::after{
      top: 70%;
      left: 80%;
      @include res(transform,translateX(10%) translateY(10%),(md:translateX(0%) translateY(0%)));
    }
    &:hover{
      box-shadow: 10px 10px 10px rgba(0,0,0,.2);
      &::after,&::before{
        opacity: 1;
        transform: translateX(0%) translateY(0%);
      }
      .item-img{
        &::after{
          opacity: 1;
        }
        b{
          transform: scale(1.1);
        }
      }
      .item-t{
        height: 100%;
        .cn{
          @include res(font-size, 30px, 20 / 30);
        }
        .en{
          opacity: .5;
        }
      }
    }
    .item-img{
      position: relative;
      &::before{
        content: '';
        display: block;
        width: 100%;
        background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.7));
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        @include res(height,50%,(md:100%));
      }
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        // background-color: rgba(48,98,245,.3);
        background-color: rgba(9,8,57,.3);
        position: absolute;
        top: 0;
        left: 0;
        transition: all .5s;
        z-index: 0;
        @include res(opacity,0,(md:1));
      }
    }
    .item-t{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 20%;
      transition: all .3s;
      color: #fff;
      text-align: center;
      @include res(white-space,nowrap,(md:normal));
      @include res(height,20%,(md:100%));
      .cn{
        transition: all .5s;
        @include res(font-size, 24px, 18 / 24);
        @include res(margin-bottom,10px, 5 / 10);
      }
      .en{
        text-transform: uppercase;
        opacity: 0;
        transition: all .5s;
        line-height: 1.2;
        @include res(opacity,0,(md:.5));
        @include res(font-size, 18px, 14 / 18);
      }
    }
  }
}

//下载列表
.download-list{
  // @extend %clearfix;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  @include res(width,calc(100% + 30px),(mmd:calc(100% + 30px),xs:100%));
  li{
    transition: all .5s;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    box-sizing: border-box;
    font-family: 'montserrat';
    background-color: #fdfdfd;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    @include res(padding-top,45px, 20 / 45);
    @include res(padding-bottom,55px, 20 / 55);
    @include res(padding-left,35px, 15 / 35);
    @include res(padding-right,35px, 15 / 35);
    @include res(width,calc(25% - 30px),(mmd:calc(25% - 30px),md:calc(50% - 30px),xs:100%));
    @include res(margin-right,30px,(mmd:30px,xs:0));
    @include res(margin-bottom,30px, 20 / 30);
    &::after,&::before{
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      border: solid rgba(48,98,245,.08);
      position: absolute;
      z-index: 2;
      box-sizing: border-box;
      transition: all .5s;
      @include res(opacity,0,(md:1));
      @include res(border-width,8px, 5 / 8);
    }
    &::before{
      bottom: 7%;
      right: 5%;
      @include res(transform,translateX(-10%) translateY(-10%),(md:translateX(0%) translateY(0%)));
    }
    &::after{
      top: 83%;
      left: 85%;
      @include res(transform,translateX(10%) translateY(10%),(md:translateX(0%) translateY(0%)));
    }
    &:hover{
      box-shadow: 5px 5px 25px rgba(0,0,0,.1);
      &::after,&::before{
        opacity: 1;
        transform: translateX(0%) translateY(0%);
      }
    }
    >div{
      position: relative;
      z-index: 3;
      .item-date{
        color: #000;
        line-height: 1;
        border-bottom: 1px solid #d9dbe5;
        @include res(font-size, 34px, 18 / 34);
        @include res(padding-bottom,25px, 10 / 25);
        @include res(margin-bottom,25px, 10 / 25);
        span{
          display: block;
          color: #666;
          @include res(margin-top,5px);
          @include res(font-size, 16px, 14 / 16);
        }
      }
      .item-title{
        p{
          word-break: break-all;
          white-space: wrap;
          overflow:hidden; 
          text-overflow:ellipsis;
          display:-webkit-box; 
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2; 
          line-height: 1.4;
          @include res(height, 62px, 45 / 62);
          @include res(font-size, 22px, 16 / 22);
          @include res(margin-bottom, 10px, 5 / 10);
        }
        span{
          color: #666;
          display: block;
          @include res(height,36px, 26 / 36);
          @include res(font-size, 20px, 14 / 20);
        }
      }
      .item-link{
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        @include res(margin-top,15px, 15 / 15);
        a{
          border: 1px solid #3062f5;
          border-radius: 50px;
          transition: all .3s;
          flex-shrink: 0;
          display: inline-block;
          @include res(margin-top,10px, 5 / 10);
          @include res(font-size,18px, 14 / 18);
          @include res(padding-left,20px, 10 / 20);
          @include res(padding-right,20px, 10 / 20);
          @include res(padding-top,5px, 3 / 5);
          @include res(padding-bottom,5px, 3 / 5);
          &:not(:last-child){
            @include res(margin-right,15px, 10 / 15);
          }
          .iconfont{
            @include res(font-size, 18px, 16 / 18);
            @include res(margin-left,5px);
          }
          &:first-child{
            background-color: #3062f5;
            color: #fff;
          }
          &:hover{
            border: 1px solid #3062f5;
            background-color: #fff;
            color: #3062f5;
          }
        }
      }
    }
  }
}

//新闻列表
.news-list{
  @include res(width,calc(100% + 50px),(mmd:calc(100% + 30px),xs:100%));
  @extend %clearfix;
  li{
    transition: all .4s;
    @include res(float,left);
    @include res(width,calc(33.33% - 50px),(mmd:calc(33.33% - 30px),md:calc(50% - 30px),xs:100%));
    @include res(margin-right,50px,(mmd:30px,xs:0));
    @include res(margin-bottom,50px,(mmd:30px,xs:20px));
    &:hover{
      box-shadow: 10px 10px 25px rgba(48,98,245,.15);
      .item-img b{
        transform: scale(1.05);
      }
      .item-text{
        .title{
          color: $color-blue;
        }
        &::after{
          transform: scaleY(1);
        }
      }
    }
    .item-text{
      position: relative;
      border: 1px solid #bdd2f4;
      border-top: none;
      @include res(padding-top,35px, 15 / 35);
      @include res(padding-bottom,45px, 20 / 45);
      @include res(padding-left,40px, 20 / 40);
      @include res(padding-right,40px, 20 / 40);
      &::after{
        content: '';
        display: block;
        width: calc(100% + 2px);
        background-color: $color-blue;
        position: absolute;
        bottom: -1px;
        left: -1px;
        transform-origin: bottom;
        transition: all .5s;
        transform: scaleY(0);
        @include res(height,10px, 5 / 10);
      }
      .title{
        color: #303030;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        transition: all .5s;
        @include res(height,72px, 48 / 72);
        @include res(font-size,24px, 16 / 24);
        @include res(margin-bottom,30px, 15 / 35);
      }
      .btm{
        border-top: 1px solid #c4c4c4;
        position: relative;
        @include res(padding-top,20px, 10 / 20);
        .date{
          color: $color-blue;
          font-family: 'montserrat';
          line-height: 1.4;
          @include res(font-size, 18px, 14 / 18);
          span{
            display: block;
            line-height: 1;
            @include res(font-size,34px,22 / 34);
          }
        }
        .link{
          position: absolute;
          bottom: 0;
          right: 0;
          span{
            border-radius: 40px;
            background-color: $color-blue;
            color: #fff;
            display: inline-block;
            transition: all .4s;
            @include res(font-size,18px, 14 / 18);
            @include res(padding-left,25px, 20 / 25);
            @include res(padding-right,25px, 20 / 25);
            @include res(padding-top,8px, 4 / 8);
            @include res(padding-bottom,10px, 5 / 10);
            .iconfont{
              transition: all .4s;
              display: inline-block;
              @include res(font-size,16px, 12 / 16);
              @include res(margin-left,8px, 5 / 8);
            }
            &:hover{
              background-color: $color-main-light;
              .iconfont{
                transform: translateX(5px);
              }
            }
          }
        }
      }
    }
  }
}