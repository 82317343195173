@import "../../assets/styles/utils";

.pro-bottom-info{
  background-image: linear-gradient(#0291ff,#0058ff);
  background-color: #0174ff;
  color: #fff;
  @include res(padding-top,70px, 20 / 70);
  @include res(padding-bottom,70px, 20 / 70);
  .mycontainer{
    @include res(text-align,null,(xs:center));
    @extend %clearfix;
    .left{
      font-weight: bold;
      @include res(float,left,(xs:none));
      @include res(font-size,36px, 18 / 36);
      @include res(margin-top,20px,(xs:0));
      @include res(margin-bottom,0,(xs:10px));
    }
    .right{
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(float,right,(xs:none));
      a{
        letter-spacing: 2px;
        @include res(float,left,(xs:none));
        @include res(font-size,20px, 16 / 20);
        &:not(:last-child){
          @include res(margin-right,30px, 15 / 30);
        }
        &:hover{
          .iconfont{
            background-color: rgba(255,255,255,1);
            color: $color-main;
          }
        }
        .iconfont{
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(255,255,255,.1);
          transition: all .3s;
          margin: 0 auto;
          @include res(width,60px, 50 / 60);
          @include res(height,60px, 50 / 60);
          @include res(font-size,30px, 20 / 30);
          @include res(margin-bottom,5px);
        }
      }
    }
  }
}