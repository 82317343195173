@import "../../assets/styles/utils";

.header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include res(padding-left,80px, 10 / 80);
  @include res(padding-right,160px, 60 / 160);
  @include res(padding-top,40px, (lg:34px,mmd:19px,md:28px,sm:22px,xs:16px));
  &.black{
    .left{
      .nav{
        a{
          color: rgba(0,0,0,.8);
          &:hover{
            color: #000;
          }
        }
      }
    }
    .logo{
      img{
        &.logo-white{
          opacity: 0;
        }
        &.logo-color{
          opacity: 1;
        }
      }
    }
    .right{
      .link{
        .link-title{
          border: 1px solid #adadad;
          color: #333;
        }
      }
      .call{
        color: #000;
      }
    }
  }
  .left{
    display:flex;
    align-items: center;
    float: left;
    .nav{
      font-weight: bold;
      @include res(display,block,(md:none));
      @include res(margin-top,5px, 0 / 5);
      @include res(margin-left,20px, 0 / 20);
      @include res(font-size,18px, 16 / 18);
      a{
        display: inline-block;
        color: rgba(255,255,255,.8);
        transition: all .4s;
        @include res(padding-left,30px, 0 / 30);
        @include res(padding-right,30px, 0 / 30);
        @include res(padding-bottom,20px, 0 / 20);
        @include res(padding-top,20px, 0 / 20);
        &:not(:last-child){
          @include res(margin-left,20px, 0 / 20);
        }
        &:hover{
          color: #fff;
        }
      }
    }
  }
  .logo{
    // float: left;
    position: relative;
    img{
      display: block;
      height: auto;
      transition: all .4s;
      @include res(width,371px, 130 / 371);
      @include res(margin-top,0,(sm:5px));
      &.logo-color{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }
  .right{
    // float: right;
    @include res(margin-bottom,-25px, -15 / -25);
    @include res(margin-right,60px, 10 / 60);
    .link{
      float: right;
      position: relative;
      @include res(padding-bottom,25px, 15 / 25);
      &:hover{
        .link-title{
          background-color: #fff;
          color: #333;
          .iconfont{
            transform: rotate(0deg);
          }
        } 
        .sub-link{
          opacity: 1;
          visibility: visible;
          transform: translateX(-50%) translateY(0%);
        }
      }
      .link-title{
        border-radius: 50px;
        // background-color: #fff;
        border: 1px solid #fff;
        cursor: pointer;
        color: #fff;
        transition: all .5s;
        @include res(font-size, 16px, 12 / 16);
        @include res(padding-left,30px, 15 / 30);
        @include res(padding-right,30px, 15 / 30);
        @include res(padding-top,4px,0 / 4);
        @include res(padding-bottom,4px,0 / 4);
        .iconfont{
          display: inline-block;
          transform-origin: center;
          transition: transform .5s;
          transform: rotate(180deg);
          @include res(margin-left, 10px, 5 / 10);
          @include res(font-size, 16px, 12 / 16);
        }
      }
      .sub-link{
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(5%);
        background-color: #fff;
        border-radius: 10px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: all .6s;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        @include res(min-width,200px,100 / 200);
        @include res(padding,20px, 10 / 20);
        &::after{
          content: '';
          display: block;
          border: 8px solid;
          border-color: transparent transparent #fff transparent;
          position: absolute;
          left: calc(50% - 8px);
          bottom: 100%;
        }
        a{
          display: block;
          transition: all .3s;
          @include res(font-size, 20px, 12 / 20);
          &:hover{
            color: $color-main;
            .iconfont{
              transform: translateX(5px);
            }
          }
          .iconfont{
            display: inline-block;
            transition: all .3s;
            @include res(font-size, 16px, 12 / 16);
            @include res(margin-left,10px, 5 / 10);
          }
        }
      }
    }
    .call{
      float: right;
      color: #fff;
      transition: all .4s;
      font-family:"harmony",'roboto',"PingFang SC","Microsoft YaHei";
      // @include res(line-height, 40px, 30 / 40);
      @include res(font-size,22px, 16 / 22);
      @include res(display,null,(xs:none));
      @include res(margin-right,20px, 10 / 20);
      .iconfont{
        @include res(font-size, 22px, 16 / 22);
        @include res(margin-right,15px, 10 / 15);
      }
    }
  }
}
.header-aside{
  .menu{
    background-color: #0054ff;
    position: fixed;
    z-index: 200;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: all .4s;
    @include res(width, 160px, 60 / 160);
    // @include res(height, 160px, 60 / 160);
    @include res(height, 120px, 60 / 120);
    @media screen and(max-width:1366px) {
      @include res(width, 120px, 60 / 120);
      @include res(height, 120px, 60 / 120);
    }
    display: flex;
    align-items: center;
    justify-content: space-around;
    &:hover{
      background-color: #012dba;
    }
    &.active{
      // justify-content: center;
      // @include res(height, 160px, 60 / 160);
      .menu-icon::before{
        margin-top:0px;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        @include res(width,36px, 16 / 36);
        @include res(height,4px, 3 / 4);
      }
      .menu-icon::after{
        margin-top:0px;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        @include res(width,36px, 16 / 36);
        @include res(height,4px, 3 / 4);
      }
      .menu-icon .bar{
        opacity: 0;
      }
      .caidan{
        &.opennone{
          display: none;
        }
        &.openblock{
          display: block;
        }
      }
    }
    .caidan{
      color: #fff;
      @include res(font-size, 24px, 12 / 24);
      @include res(margin-right, -16px, -6 / -24);
      @include res(margin-left, 26px, 6 / 26);
      transition: all .3s;
      &.opennone{
        display: block;
      }
      &.openblock{
        display: none;
      }
    }
    .line{
      width: 1px;
      height: 34%;
      background-color: rgba(255,255,255,.4);
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      @include res(margin-left,-6px, 0 / -6);
      @include res(margin-right,-11px, 0 / -11);
    }
    .menusousuo{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48%;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      .iconfont{
        color: #fff;
        @include res(font-size,24px, 18 / 24);
      }
    }
    .menu-icon{
      cursor: pointer;
      transition: all .4s;
      position: relative;
      width: 48%;
      height: 48%;
      &::before,&::after{
        content: '';
        display: block;
        background-color: #fff;
        transition: all .3s;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transform-origin: center;
        @include res(width,30px, 16 / 30);
        @include res(height,3px, 1 / 3);
      }
      &::before{
        // @include res(margin-top,-6px, -4 / -6);
        @include res(margin-top,-9px, -6 / -9);
      }
      &::after{
        // @include res(margin-top,6px, 4 / 6);
        @include res(margin-top,9px, 6 / 9);
      }
      .bar{
        display: block;
        opacity: 1;
        background-color: #fff;
        transition: all .3s;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transform-origin: center;
        @include res(width,30px, 16 / 30);
        @include res(height,3px, 1 / 3);
        margin-top: 0px;
      }
    }
  }
  .btn-icon{
    position: fixed;
    bottom: 5%;
    right: 0;
    z-index: 10;
    overflow: hidden;
    @include res(width, 160px, 60 / 160);
    @media screen and(max-width:1366px) {
      @include res(width, 120px, 60 / 120);
    }
    &.scroll{
      .message{
        transform: translateY(0%);
      }
      .back-top{
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
    .message,.back-top{
      border-radius: 50%;
      margin: 0 auto;
      position: relative;
      cursor: pointer;
      @include res(margin-bottom,20px, 10 / 20);
      @include res(width,90px, 40 / 90);
      @include res(height,90px, 40 / 90);
      @media screen and(max-width:1366px) {
        @include res(width,70px, 40 / 70);
        @include res(height,70px, 40 / 70);
      }
      .iconfont{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .message{
      color: #fff;
      background-color: $color-main-light;
      background-image: linear-gradient(#0096ff,#0054ff);
      transform: translateY(100%);
      transition: all .5s;
      position: relative;
      z-index: 1;
      .iconfont{
        transition: all .3s;
        @include res(font-size, 40px, 22 / 40);
      }
      &:hover{
        .iconfont{
          @include res(font-size, 46px, 24 / 46);
        }
      }
    }
    .back-top{
      background-color: #ffffff;
      color: $color-main;
      transition: all .5s;
      box-shadow: 0px 5px 15px rgba(0,0,0,.1);
      opacity: 0;
      visibility: hidden;
      transform: scale(0);
      transform-origin: center;
      .iconfont{
        @include res(font-size, 26px, 18 / 26);
      }
      &:hover{
        transform: translateY(-5px);
        box-shadow: 0px 5px 20px rgba(0,0,0,.1);
      }
    }
  }
}

.header-nav{
  position: fixed;
  height: 100vh;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: 100;
  // background: url(./images/header_bg.jpg) no-repeat bottom right #ffffff;
  background-color: #fff;
  background-size: cover;
  box-sizing: border-box;
  transition: all .6s $anime-bezier;
  overflow: hidden;
  @include res(padding-top,150px, 70 / 150);
  @media screen and(max-width:1366px) {
    @include res(padding-top,130px, 70 / 130);
  }
  &.show-nav{
    transform: translateY(100%);
    .mycontainer{
      // @media (max-width: 1600px){
      //   overflow: auto;
      // }
      .nav ul li{
        opacity: 1;
        transform: translateX(0%);
        &:nth-child(1){
          transition-delay: .5s;
        }
        &:nth-child(2){
          transition-delay: .6s;
        }
        &:nth-child(3){
          transition-delay: .7s;
        }
        &:nth-child(4){
          transition-delay: .8s;
        }
        &:nth-child(5){
          transition-delay: .9s;
        }
      }
      .navpc .item .ulb li{
        opacity: 1;
        transform: translateY(0%);
        &:nth-child(1){
          transition-delay: .5s;
        }
        &:nth-child(2){
          transition-delay: .6s;
        }
        &:nth-child(3){
          transition-delay: .7s;
        }
        &:nth-child(4){
          transition-delay: .8s;
        }
        &:nth-child(5){
          transition-delay: .9s;
        }
        &:nth-child(6){
          transition-delay: 1s;
        }
        &:nth-child(7){
          transition-delay: 1.1s;
        }
        &:nth-child(8){
          transition-delay: 1.2s;
        }
      }
      .call{
        opacity: 1;
        transform: translateY(0%);
        transition-delay: .6s;
      }
    }
  }
  .top{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    @include res(padding-left,80px, 10 / 80);
    @include res(padding-right,160px, 60 / 160);
    // @include res(padding-top,60px, 10 / 60);
    @include res(height, 120px, 60 / 120);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-logo{
      float: left;
      position: relative;
      img{
        display: block;
        height: auto;
        transition: all .4s;
        @include res(width,371px, 130 / 371);
        @include res(margin-top,0,(sm:5px));
      }
    }
    .right{
      float: right;
      @include res(line-height,38px, 30 / 38);
      @include res(margin-right,40px, 10 / 40);
      .link{
        float: right;
        position: relative;
        @include res(margin-top,25px, 15 / 25);
        @include res(padding-bottom,25px, 15 / 25);
        &:hover{
          .link-title{
            background-color: #fff;
            color: #333;
            .iconfont{
              transform: rotate(0deg);
            }
          } 
          .sub-link{
            opacity: 1;
            visibility: visible;
            transform: translateX(-50%) translateY(0%);
          }
        }
        .link-title{
          border-radius: 50px;
          border: 1px solid #adadad;
          color: #333;
          cursor: pointer;
          transition: all .5s;
          @include res(font-size, 16px, 12 / 16);
          @include res(padding-left,30px, 15 / 30);
          @include res(padding-right,30px, 15 / 30);
          // @include res(padding-top,4px,0 / 4);
          // @include res(padding-bottom,4px,0 / 4);
          @include res(height,38px, 24 / 38);
          @include res(line-height,38px, 21 / 38);
          .iconfont{
            display: inline-block;
            transform-origin: center;
            transition: transform .5s;
            transform: rotate(180deg);
            @include res(margin-left, 10px, 5 / 10);
            @include res(font-size, 16px, 12 / 16);
          }
        }
        .sub-link{
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%) translateY(5%);
          background-color: #fff;
          border-radius: 10px;
          white-space: nowrap;
          opacity: 0;
          visibility: hidden;
          transition: all .6s;
          box-shadow: 0 0 10px rgba(0,0,0,.1);
          @include res(min-width,200px,100 / 200);
          @include res(padding,20px, 10 / 20);
          &::after{
            content: '';
            display: block;
            border: 8px solid;
            border-color: transparent transparent #fff transparent;
            position: absolute;
            left: calc(50% - 8px);
            bottom: 100%;
          }
          a{
            display: block;
            transition: all .3s;
            @include res(font-size, 20px, 12 / 20);
            &:hover{
              color: $color-main;
              .iconfont{
                transform: translateX(5px);
              }
            }
            .iconfont{
              display: inline-block;
              transition: all .3s;
              @include res(font-size, 16px, 12 / 16);
              @include res(margin-left,10px, 5 / 10);
            }
          }
        }
      }
      .touxiang{
        float: right;
        position: relative;
        @include res(padding-left,21px, 11 / 21);
        @include res(margin-left,10px, 5 / 10);
        @include res(margin-top,25px, 15 / 25);
        @include res(margin-right,40px, 10 / 40);
        &::after{
          content: '';
          display: block;
          width: 1px;
          background-color: #dadffd;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          @include res(height,26px, 20 / 26);
        }
        img{
          display: block;
          border-radius: 50%;
          @include res(width,38px, 30 / 38);
          @include res(height,38px, 30 / 38);
        }
      }
      .nav-search{
        float: right;
        position: relative;
        transform: all .3s;
        @include res(margin-top,25px, 15 / 25);
        @include res(margin-right,40px, 10 / 40);
        @include res(display,block,(xs:none));
        &.active{
          box-shadow: 0px 0px 10px rgba(0,0,0,.1);
          form{
            opacity: 1;
            visibility: visible;
            input{
              @include res(width,200px, 80 / 200);
            }
          }
          .open-search{
            .iconfont{
              &.icon-guanbi{
                display: block;
              }
              &.icon-sousuo{
                display: none;
              }
            }
          }
        }
        form{
          position: relative;
          visibility: hidden;
          transform-origin: right;
          transition: all .4s;
          opacity: 0;
          @include res(padding-left,50px,25 / 50);
          button{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            outline: none;
            background-color: transparent;
            border: none;
            z-index: 1;
            cursor: pointer;
            .iconfont{
              @include res(font-size, 26px, 18 / 26);
            }
          }
          input{
            height: 100%;
            outline: none;
            background-color: transparent;
            border: none;
            transition: all .4s;
            @include res(width,0px);
          }
        }
        .open-search{
          position: absolute;
          top: 0;
          right: 10px;
          z-index: 1;
          cursor: pointer;
          .iconfont{
            color: #7f7f7f;
            @include res(font-size, 26px, 18 / 26);
            &.icon-guanbi{
              display: none;
            }
          }
        }
      }
    }
  }
  .mycontainer{
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    @include res(overflow-y,hidden,(md:auto));
    @include res(padding-left, 130px, 20 / 130);
    @include res(padding-right, 130px, 20 / 130);
    @include res(padding-bottom, 100px, 20 / 100);
    .navpc{
      @include res(display,flex,(md:none));
      @include res(padding-top, 30px, 20 / 30);
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      .item{
        width: 20%;
        // @include res(width,20%,(mmd:47%));
        margin-top: 40px;
        @media screen and(max-width:1367px) {
          margin-top: 0px;
        }
        // min-width: 300px;
       .ittitle{
          // @include res(font-size, 33px, 18 / 33);
          @include res(font-size, 30px, 14 / 30);
          color: #41454a;
          @include res(padding-bottom, 14px, 5 / 14);
          border-bottom: 1px solid #cccccc;
          // font-family: 'PingFang SC';
          // font-weight: 600;
          margin-bottom: 16px;
       }
       .ulb{
          li{
            @include res(font-size, 24px, 14 / 24);
            // @include res(font-size, 22px, 14 / 22);
            margin-bottom: 6px;
            // transition: all .5s;
            // font-family: 'PingFang SC';
            transition: all .4s;
            transform: translateX(10%);
            opacity: 0;
            a{
              color: #6a6f72;
              transition: all .5s;
            }
            &:hover{
              a{
                color: #0032d2;
              }
            }
          }
       }
      }
    }
    .nav{
      color: #999999;
      white-space: nowrap;
      @include res(display,none,(md:block));
      ul{
        li{
          position: relative;
          z-index: 1;
          transition: all .4s;
          transform: translateX(10%);
          opacity: 0;
          @include res(margin-top,35px, 0 / 35);
          @include res(border-bottom,none,(md:1px solid rgba(0,0,0,.1)));
          &.pc-active{
            z-index: 2;
            // .nav-title{
            //   color: $color-main;
            //   @include res(font-size, 32px, 24 / 32);
            //   &::after{
            //     width: 100%;
            //   }
            // }
            .sub-nav{
              .item{
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                &:nth-child(2){
                  transition-delay: .1s;
                }
                &:nth-child(3){
                  transition-delay: .15s;
                }
                &:nth-child(4){
                  transition-delay: .2s;
                }
                &:nth-child(5){
                  transition-delay: .25s;
                }
                &:nth-child(6){
                  transition-delay: .3s;
                }
                &:nth-child(7){
                  transition-delay: .35s;
                }
                &:nth-child(8){
                  transition-delay: .4s;
                }
                &:nth-child(9){
                  transition-delay: .45s;
                }
                &:nth-child(10){
                  transition-delay: .5s;
                }
              }
            }
          }
          &.mob-active{
            .nav-title{
              color: $color-main;
              @include res(font-size, 32px, 24 / 32);
              &::after{
                width: 100%;
              }
              .iconfont{
                transform: rotate(90deg);
              }
            }
          }
          .nav-title{
            position: relative;
            transition: all .3s;
            @include res(line-height, 40px, 30 / 40);
            @include res(font-size, 26px, 18 / 26);
            @include res(width,162px,(md:100%));
            @include res(padding-top,10px);
            @include res(padding-bottom,10px);
            &::after{
              content: '';
              height: 1px;
              width: 0px;
              display: block;
              background-color: $color-main;
              position: absolute;
              bottom: 0;
              left: 0;
              transition: all .3s;
            }
            a{
              display: block;
            }
            .iconfont{
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 50px;
              align-items: center;
              justify-content: center;
              z-index: 1;
              transition: all .4s;
              @include res(display,none,(md:flex));
            }
          }
          .sub-nav{
            top: 0;
            z-index: 2;
            @extend %clearfix;
            @include res(left,20%);
            @include res(width,80%,(md:100%));
            @include res(position,absolute,(md:static));
            @include res(display,block,(md:none));
            @include res(margin-top,10px);
            @include res(margin-bottom,10px);
            @media screen and (max-width:1024px){
              @include res(padding-top, 50px, 15 / 50);
              @include res(padding-bottom, 50px, 15 / 50);
            }
            .item{
              position: relative;
              transition: all .5s;
              @include res(float,left);
              @include res(width,20%,(md:25%,sm:33.33%,xs:50%));
              @include res(opacity,0,(md:1));
              @include res(visibility,hidden,(md:visible));
              @include res(transform,translateY(10%),(md:translateY(0%)));
              &:hover{
                .sub-nav-title{
                  color: $color-main;
                }
                .c-sub-title{
                  // opacity: 1;
                  opacity: 0;
                  visibility: visible;
                }
              }
              .sub-nav-title{
                transition: all .3s;
                // @include res(padding-top,10px);
                // @include res(padding-bottom,10px);
                @include res(line-height, 40px, 30 / 40);
                // @include res(font-size, 26px,16 / 26);
                @include res(font-size, 22px,16 / 22);
                @media screen and (max-width:767px) {
                  padding: 5px 0;
                }
              }
              .c-sub-title{
                position: absolute;
                top: 100%;
                left: 0;
                opacity: 0;
                visibility: hidden;
                transition: all .3s;
                z-index: 3;
                @include res(display,block,(md:none));
                a{
                  display: block;
                  color: #5f5f5f;
                  transition: all .3s;
                  @include res(font-size, 18px, 14 / 18);
                  @include res(padding-top,10px, 3 / 10);
                  &:hover{
                    color: $color-main;
                  }
                  .iconfont{
                    @include res(font-size, 16px, 12 / 16);
                    @include res(margin-left,10px, 5 / 10);
                  }
                }
              }
            }
          }
        }
      }
    }
    .call{
      color: $color-main;
      font-family:"harmony",'roboto',"PingFang SC","Microsoft YaHei";
      opacity: 0;
      transform: translateY(10%);
      transition: all .5s;
      @include res(border-top,1px solid #cccccc,(md:none));
      @include res(font-size, 32px, 20 / 32);
      @include res(margin-top,110px, 30 / 110);
      @include res(padding-top,39px, 10 / 39);
      @media screen and(max-width:1367px) {
        margin-top: 20px;
        padding-top: 10px;
      }
      @media screen and(max-width:1024px) {
        @include res(margin-top,100px, 30 / 100);
        @include res(padding-top,30px, 10 / 30);
      }
      .iconfont{
        color: #333;
        @include res(margin-right,15px, 10 / 15);
        @include res(font-size,30px, 22 / 30);
      }
    }
    .searchmob{
      position: relative;
      transform: all .3s;
      @include res(margin-top,30px);
      @include res(display,none,(xs:block));
      height: 36px;
      line-height: 36px;
      &.active{
        box-shadow: 0px 0px 10px rgba(0,0,0,.1);
        form{
          opacity: 1;
          visibility: visible;
          input{
            @include res(width,200px, 80 / 200);
          }
        }
        .open-search{
          .iconfont{
            &.icon-guanbi{
              display: block;
            }
            &.icon-sousuo{
              display: none;
            }
          }
        }
      }
      form{
        position: relative;
        visibility: hidden;
        transform-origin: right;
        transition: all .4s;
        opacity: 0;
        @include res(padding-left,50px,25 / 50);
        button{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          outline: none;
          background-color: transparent;
          border: none;
          z-index: 1;
          cursor: pointer;
          .iconfont{
            @include res(font-size, 26px, 18 / 26);
          }
        }
        input{
          height: 100%;
          outline: none;
          background-color: transparent;
          border: none;
          transition: all .4s;
          @include res(width,0px);
        }
      }
      .open-search{
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 1;
        cursor: pointer;
        .iconfont{
          color: #7f7f7f;
          @include res(font-size, 26px, 18 / 26);
          &.icon-guanbi{
            display: none;
          }
        }
      }
    }
  }
}