@font-face {
  font-family: "iconfont"; /* Project id 3911319 */
  src: url('iconfont.woff2?t=1684286487014') format('woff2'),
       url('iconfont.woff?t=1684286487014') format('woff'),
       url('iconfont.ttf?t=1684286487014') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-laiyuangailan:before {
  content: "\e61d";
}

.icon-xinwen-laiyuan:before {
  content: "\e675";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-dingwei:before {
  content: "\e602";
}

.icon-24gf-telephone2:before {
  content: "\e96d";
}

.icon-shijian:before {
  content: "\e64e";
}

.icon-yanjing:before {
  content: "\e8bf";
}

.icon-yonghu:before {
  content: "\e685";
}

.icon-qiyeyuanjing:before {
  content: "\e668";
}

.icon-shiming:before {
  content: "\e66a";
}

.icon-hexinjiazhiguan:before {
  content: "\e611";
}

.icon-shu:before {
  content: "\e62b";
}

.icon-shipin:before {
  content: "\e64d";
}

.icon-dingzhibaobiao:before {
  content: "\e62d";
}

.icon-gongneng:before {
  content: "\e875";
}

.icon-xiazai:before {
  content: "\e725";
}

.icon-renminbi:before {
  content: "\e66b";
}

.icon-dianhua:before {
  content: "\e64b";
}

.icon-zixunjilu1:before {
  content: "\e6e7";
}

.icon-guanbi:before {
  content: "\eaf2";
}

.icon-zhihu1:before {
  content: "\e6ba";
}

.icon-linkedin1:before {
  content: "\e696";
}

.icon-jinrujiantouleft:before {
  content: "\eaf3";
}

.icon-xinlangweibo:before {
  content: "\e600";
}

.icon-tianmaologo2:before {
  content: "\e601";
}

.icon-weixin:before {
  content: "\e632";
}

.icon-xiazai16:before {
  content: "\e610";
}

.icon-jingdong:before {
  content: "\e62c";
}

.icon-youjiantou:before {
  content: "\e624";
}

.icon-zuojiantou:before {
  content: "\e625";
}

.icon-jinrujiantouxiao:before {
  content: "\e8d4";
}

.icon-24gl-phoneLoudspeaker:before {
  content: "\e94f";
}

.icon-xiangshang:before {
  content: "\e6ca";
}

.icon-jinritoutiao:before {
  content: "\e68d";
}

.icon-sousuo:before {
  content: "\e60f";
}

